/* src/App.css - Enhanced styling with performance optimizations */

html, body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.App {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  max-width: 100vw;
}


/* Content container fix */
.content {
  padding: 40px 20px;
  max-width: 100%;
  width: auto;
  margin: 0 auto;
  box-sizing: border-box;
}


h1 {
  font-size: 3em;
  margin-bottom: 10px;
  background: linear-gradient(45deg, #4a90e2, #2a6ac2);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

p {
  font-size: 1.4em;
  margin-bottom: 30px;
  color: #555;
}



.imprint {
  margin-top: 60px;
  padding: 30px 20px;
  background-color: #f0f2f5;
  font-size: 0.9em;
  line-height: 1.6;
  color: #666;
  border-top: 1px solid #e0e0e0;
}

/* Performance optimized containers */
.neural-network-container {
  width: 100%;
  height: 300px; /* Reduced from 500px to 300px for a smaller container */
  background-color: #0b1222;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3); /* Reduced shadow intensity for a subtler effect */
  will-change: transform;
  contain: content;
  max-width: 100%;
}

.letter-network-container {
  width: 100%;
  height: 300px;
  margin: 40px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  will-change: transform; /* Performance hint for browser */
  contain: content; /* Helps performance by isolating this content */
}

/* Loading spinner for 3D content */
.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #4a90e2;
}

.spinner {
  border: 4px solid rgba(74, 144, 226, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4a90e2;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive optimizations */
@media (max-width: 768px) {
  h1 {
    font-size: 2em;
  }
  
  p {
    font-size: 1.2em;
  }
  
  .neural-network-container {
    height: 400px; /* Further reduced for mobile */
  }
  
  .letter-network-container {
    height: 200px; /* Further reduced for mobile */
  }
  
  /* Simplified gradients for mobile */
  h1 {
    background: #4a90e2; /* Solid color instead of gradient for performance */
    -webkit-background-clip: initial;
    background-clip: initial;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
  
  .schedule-link {
    background: #4a90e2; /* Solid color instead of gradient for performance */
  }
  
  .schedule-link:hover {
    background: #3a80d2;
  }
}

/* Low power device optimizations */
@media (prefers-reduced-motion: reduce) {
  .schedule-link {
    transition: none; /* Disable animations for users who prefer reduced motion */
  }
  
  .schedule-link:hover {
    transform: none;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

.imprint p {
  font-size: 0.8em;
  margin: 0;
  margin-bottom: 0.2em;
}

.imprint p:last-child {
  margin-bottom: 0;
}

.email-container {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f2f5;
  border-radius: 5px;
  font-size: 1.1em;
}

/* Optional: Style the button to match your design */
.schedule-link {
  padding: 10px 20px;
  font-size: 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.schedule-link:hover {
  background-color: #0056b3;
}


/* src/components/EmailModal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-overlay.open {
  opacity: 1;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 400px;
  width: 100%;
  text-align: center;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.modal-content.open {
  transform: scale(1);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
}

.modal-content p {
  font-size: 1.2em;
  margin: 0;
}

.modal-content a {
  color: #007bff;
  text-decoration: none;
}

.modal-content a:hover {
  text-decoration: underline;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
}

.imprint-details {
  text-align: left;
  flex: 1;
  min-width: 200px;
  max-width: 60%;
}

.logo-container {
  flex: 0 0 auto;
  width: 200px; /* Reduced width */
  max-width: 200px; /* Constrained max width */
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-logo {
  opacity: 0.6;
  transition: opacity 0.3s ease;
  width: 100%;
  height: auto;
  max-width: 100%;
}

.footer-logo:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .content {
    padding: 20px 15px;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  
  .imprint-details {
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%;
  }
  
  .logo-container {
    width: 180px;
    max-width: 180px;
    justify-content: center;
  }
}